.airPollutionVisual {
  max-width: 100%;
  height: auto;
  width: 100%;
  max-width: 420px;
  padding: 20px 0;
}

.airPollutionContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.airPollutionTextBox {
  font-size: 14px;
}

.sensorDemo {
  width: 320px;
}

@media (min-width: 1024px) {
  .airPollutionTextBox {
    font-size: 16px;
  }
}

.header {
  display: flex;
  flex-direction: row;
  padding: 20px;
  height: 58px;
  justify-content: space-between;
  align-items: center;
  font-style: italic;
}

.name {
  color: white;
  font-size: 24px;
  font-weight: 600;
}

.logo {
  height: 100%;
  allign: right;
  cursor: pointer;
}

.nameLink {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: black;
  color: white;
  padding: 20px;
  border: white solid 1px;
  position: relative;
  width: 80%;
  max-width: 500px;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: white;
}

.modalContentList {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.modalContentListItem {
  padding: 20px 0;
}

a {
  text-decoration: none;
  color: inherit;
}

@media (min-width: 1024px) {
  .header {
    padding: 30px;
    font-size: 36px;
    height: 88px;
  }
  .name {
    font-size: 36px;
  }
}

@media (hover: hover) {
  .contentListItemHome:hover {
    color: #ffed00;
  }

  .contentListItemProjects:hover {
    color: #e3007a;
  }

  .contentListItemContact:hover {
    color: #009ee1;
  }
}

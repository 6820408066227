.overlay {
  width: 100vw;
  height: 100vh;
  background: rgba(7, 1, 1, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  pointer-events: none;
}

.gradientOverlay {
  width: 100vw;
  height: 40vw;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), transparent);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  pointer-events: none;
}
@media (min-width: 1024px) {
  .gradientOverlay {
    width: 40vw !important;
    height: 100vh !important;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8), transparent);
  }
}

.gradientOverlay2 {
  width: 100vw;
  height: 40vw;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 6;
  pointer-events: none;
}
@media (min-width: 1024px) {
  .gradientOverlay2 {
    display: none;
  }
}

.information {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 10;
  background: transparent;
  padding: 12px 24px 0px;
  align-items: start;
  height: 100%;
  pointer-events: none;
}
@media (min-width: 1024px) {
  .information {
    gap: 24px;
    padding: 32px;
  }
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  pointer-events: auto;
}
@media (min-width: 1024px) {
  .titleWrapper {
    gap: 24px;
  }
}

.title {
  display: flex;
  color: #ff8c00;
  font-size: 28px;
  font-family: "Red Hat Display";
}
@media (min-width: 768px) {
  .title {
    font-size: 52px;
  }
}

.searchWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  /* justify-items: left; */
}

.searchError {
  color: #ff8c00;
  font-size: 20px;
}

.backendError {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ff8c00;
  font-size: 50px;
  font-weight: bold;
  z-index: 10;
}

.metrics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  text-align: left;
  margin-bottom: 12px;
  margin-top: auto;
}
@media (min-width: 1024px) {
  .metrics {
    margin-top: 0;
    align-items: start;
    display: block;
    gap: 24px;
  }
}

.text {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 8px;
  font-size: 14px;
}
@media (min-width: 768px) {
  .text {
    font-size: 20px;
    margin-bottom: 16px;
  }
}
@media (min-width: 1600px) {
  .text {
    flex-direction: row;
    align-items: flex-end;
  }
}

.metric {
  font-weight: bold;
  color: orange;
  font-size: 16px;
}
@media (min-width: 768px) {
  .metric {
    font-size: 24px;
  }
}

.searchBar {
  text-align: left;
  display: flex;
}

.searchBar input {
  color: white;
  border: 2px solid orange;
  border-radius: 10px;
  padding: 4px;
  background: transparent;
  font-family: "Red Hat Display";
  font-size: 16px;
}
@media (min-width: 768px) {
  .searchBar input {
    padding: 8px;
    font-size: 20px;
  }
}

.searchBar input::placeholder {
  color: white;
}

.searchBar button {
  color: white;
  background-color: orange;
  border: 2px solid orange;
  border-radius: 10px;
  padding: 8px 12px;
  margin-left: 16px;
  cursor: pointer;
  font-family: "Red Hat Display";
  font-size: 16px;
}
@media (min-width: 768px) {
  .searchBar button {
    font-size: 20px;
  }
}

.searchBar button:hover {
  background-color: #ff8c00;
  border: 2px solid #ff8c00;
}

.mask {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 15;
  opacity: 60%;
}

.maskBorder {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 15;
  border: 4px solid orange;
  width: 320px;
  height: 320px;
  pointer-events: none;
}
@media (min-width: 768px) {
  .maskBorder {
    width: 640px !important;
    height: 640px !important;
  }
}

.metrics,
.maskBorder,
.mask {
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: -5;
}

.loadingIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 15;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.show {
  opacity: 1;
  z-index: 5;
}

.showMask {
  opacity: 60%;
  z-index: 5;
}

.map {
  font-family: "Red Hat Display";
}

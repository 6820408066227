.reachContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  width: 100%;
}

.reachVisual {
  max-width: 100%;
  height: auto;
  width: 100%;
  max-width: 420px;
  padding: 20px 0;
}

.reachFileContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 40px 0;
}

.contactButton {
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  color: white;
  background-color: transparent;
  padding: 2px 10px 2px 8px;
  border-radius: 20px;
  margin-bottom: 20px;
  border: 2px solid white;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .reachContentContainer {
    font-size: 16px;
  }
  .contactButton {
    font-size: 24px;
  }
}

@media (hover: hover) {
  .contactButton:hover {
    background-color: white;
    color: #000;
  }
}

.section1 {
  margin-top: 40px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 40px;
  font-size: 20px;
  align-items: center;
  justify-content: center;
}

.text {
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.italic {
  font-style: italic;
}

.grey {
  color: #9b9b9b;
}

.arrows {
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.sectionContainer {
  border-top: 2px solid white;
  margin: 20px;
}

.sectionTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.sectionTitle.right {
  justify-content: flex-end;
}

.icon {
  padding: 20px 20px 20px 0;
}

.icon.right {
  padding: 20px 0px 20px 20px;
}

.sectionTitleText {
  margin-top: 20px;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 20px;
}

#defaultCanvas1 {
  display: none;
}

.footer {
  display: flex;
  color: #9b9b9b;
  margin-top: 80px;
  margin-bottom: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

@media (min-width: 768px) {
  .section1 {
    margin-top: 100px;
    padding: 0;
    gap: 40px;
  }
  .arrows {
    margin-top: 40px;
  }
  .sectionContainer {
    margin: 40px;
  }
  .section {
    max-width: 1024px;
    margin: 0 auto 0 auto;
  }
  .sectionTitle {
    margin-top: 20px;
  }
}

@media (min-width: 1024px) {
  .footer {
    font-size: 16px;
  }
  .sectionTitleText {
    font-size: 24px;
  }
  .section1 {
    font-size: 24px;
  }
}

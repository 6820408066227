.contactContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.contactButton {
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  color: white;
  background-color: transparent;
  padding: 2px 10px 2px 8px;
  border-radius: 20px;
  margin-bottom: 20px;
  border: 2px solid white;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .contactButton {
    font-size: 24px;
  }
}

@media (hover: hover) {
  .contactButton:hover {
    background-color: white;
    color: #000;
  }
}

.edpContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  width: 100%;
  padding-bottom: 20px;
}

.edpVisual {
  max-width: 100%;
  height: auto;
  width: 100%;
  max-width: 420px;
  padding: 20px 0;
}

@media (min-width: 1024px) {
  .edpContentContainer {
    font-size: 16px;
  }
}

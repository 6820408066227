.projectsPageWrapper {
  padding: 20px;
  position: relative;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.projectsPageListContainer {
  display: flex;
  flex-direction: column;
}

.subheading {
  margin: 20px 0;
  font-size: 14px;
  color: #9b9b9b;
}

.projectsPageItem {
  padding: 10px 0;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ccc;
  cursor: pointer;
}

.projectsPageItemDate {
  color: #9b9b9b;
}

.projectsPageItemTitle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.verticalLine {
  display: flex;
  margin-top: -20px;
  margin-bottom: -20px;
  margin-left: 20px;
  height: calc(100vh - 148px);
  border-left: 3px solid #ccc;
}

.selectedProjectContentBox {
  display: flex;
  padding: 20px 20px 0 20px;
  margin-left: -3px;
  margin-bottom: -20px;
  border-left: 3px solid #ccc;
}

.lightBlueBorder {
  border-left: 3px solid #00ffff;
}

.greenBorder {
  border-left: 3px solid #00ff00;
}

.yellowBorder {
  border-left: 3px solid #ffff00;
}

.redBorder {
  border-left: 3px solid #ff0000;
}

.pinkBorder {
  border-left: 3px solid #ff00ff;
}

.blueBorder {
  border-left: 3px solid #0000ff;
}

@media (min-width: 1024px) {
  .projectsPageWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-top: 2px solid #ccc;
    font-size: 16px;
  }
  .projectsPageList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 308px;
  }
  .projectsPageItem {
    padding: 5px 0;
    border-top: none;
  }
  .projectsArrow {
    display: none;
  }
  .projectsPageItemDate {
    margin-left: 80px;
  }
  .selectedProjectContentList {
    display: none;
  }
  .subheading {
    font-size: 16px;
  }
  .subheadingContainer {
    margin-top: 20px;
  }
  .projectsPageItemTitle {
    position: relative;
    left: auto;
    transform: none;
    text-align: left;
  }
}

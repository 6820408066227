.phrase {
  display: inline-block;
  padding: 2px 11px 2px 8px;
  border-radius: 20px;
  border: 2px solid var(--phrase-color);
  color: white;
  margin: 5px;
  font-size: 16px;
  /* font-style: italic; */
  font-weight: 800;
  transition: var(--background-color) 0.3s;
  background-color: var(--background-color);
}

.phrase.deselected {
  border: 2px solid #9b9b9b;
  color: #9b9b9b;
  transition: #9b9b9b 0.3s;
}

@media (min-width: 768px) {
  .phrase {
    font-size: 20px;
  }
}

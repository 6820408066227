.container {
  display: flex;
  align-items: flex-start;
  border-left: 2px solid white;
  width: 100%;
  margin: 20px 0 40px 0;
  position: relative;
}

.buttonList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 20px;
  width: 100%;
}

.button {
  background-color: transparent;
  cursor: pointer;
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  color: white;
  border-radius: 15px;
  border: 2px solid #009ee1;
  margin-bottom: 20px;
  display: inline-block;
}

.lastButton {
  margin-bottom: 0;
}

.lastButton.largeScreen {
  margin-bottom: 20px;
}

.lastButton.active {
  margin-bottom: 20px;
}

.content {
  overflow: hidden;
  transition: max-height 0.6s ease;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.buttonContainer.active {
  margin-left: -26px;
  padding-left: 20px;
  border-left: 6px solid white;
}

.content.active {
  margin-left: -26px;
  padding-left: 20px;
  border-left: 6px solid white;
  margin-bottom: 40px;
}

.content.largeScreen {
  max-height: 150px;
  position: relative;
  margin-bottom: 20px;
  transition: max-height 0.3s ease;
}

.content.largeScreen.active {
  max-height: none;
}

.buttonOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(to bottom, transparent, #000);
  pointer-events: none;
  transition: opacity 0.3s ease;
  opacity: 1;
}

.buttonOverlay.active {
  opacity: 0;
}

.content.active.lastButton {
  margin-bottom: 0;
}

.cyan {
  border: 2px solid #009ee1;
}

.button.active.cyan {
  background-color: #009ee1;
}

.magenta {
  border: 2px solid #e3007a;
}

.button.active.magenta {
  background-color: #e3007a;
}

.yellow {
  border: 2px solid #ffed00;
}

.button.active.yellow {
  background-color: #ffed00;
}

@media (min-width: 540px) {
  .clickMe {
    left: 45%;
  }
}

@media (min-width: 750px) {
  .clickMe {
    left: 35%;
  }
}

@media (min-width: 768px) {
  .container {
    border-left: none;
  }
  .buttonList {
    margin-left: 0;
  }
  .buttonContainer.active {
    border-left: none;
    margin-left: 0;
    padding-left: 0;
  }
  .content.active {
    border-left: none;
    margin-left: 0;
    padding-left: 0;
  }
}

@media (min-width: 1024px) {
  .button {
    font-size: 24px;
  }
}

@media (min-width: 1440px) {
  .clickMe {
    left: -15%;
  }
}

@media (hover: hover) {
  .cyan:hover {
    background-color: #009ee1;
  }
  .magenta:hover {
    background-color: #e3007a;
  }
  .yellow:hover {
    background-color: #ffed00;
  }
}

.projectContainer {
  display: flex;
  flex-direction: column;
  gap: 80px;
  margin-top: 80px;
}

.rowContainer {
  display: flex;
  flex-direction: column;
}

.projectsTitle {
  position: absolute;
}

.projectsTitle.odd {
  top: -70px;
  left: -70px;
}

.projectsTitle.even {
  right: -70px;
  top: -70px;
}

.imageContainer {
  flex: 1;
  margin: 0 auto;
  position: relative;
  max-width: 70%;
}

.image {
  max-width: 100%;
  height: auto;
  border-radius: 16px;
}

.image.zero {
  width: 100%;
  max-width: 420px;
  border: 2px solid #00ffff;
}

.image.one {
  width: 100%;
  max-width: 420px;
  border: 2px solid #00ff00;
}

.image.two {
  border: 2px solid #ffff00;
  width: 100%;
  max-width: 420px;
}

.image.three {
  border: 2px solid #ff0000;
  width: 100%;
  max-width: 420px;
}

.image.four {
  border: 2px solid #ff00ff;
  width: 100%;
  max-width: 420px;
}

.image.five {
  border: 2px solid #0000ff;
  width: 100%;
  max-width: 420px;
}

.projectsTextBox {
  flex: 1;
  padding: 40px 20px;
  color: white;
}

.projectsText {
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 14px;
}

.projectsSubtitle {
  color: #9b9b9b;
  margin-bottom: 10px;
  font-size: 14px;
}

.projectsFindOutMore {
  text-decoration-line: underline;
  font-size: 14px;
}

.projectsFindOutMore.yellowLink {
  color: #ffff00;
}

.projectsFindOutMore.lightBlueLink {
  color: #00ffff;
}

.projectsFindOutMore.greenLink {
  color: #00ff00;
}

.projectsFindOutMore.redLink {
  color: #ff0000;
}

.projectsFindOutMore.pinkLink {
  color: #ff00ff;
}

.projectsFindOutMore.blueLink {
  color: #0000ff;
}

.moreProjects {
  color: white;
  text-decoration-line: underline;
  font-size: 14px;
}

@media (min-width: 768px) {
  .rowContainer {
    flex-direction: row;
  }
  .projectsTitle.odd {
    right: -90px;
    left: auto;
  }
  .projectsTitle.even {
    left: -90px;
    right: auto;
  }
  .imageContainer {
    flex-direction: none;
  }
  .projectsTextBox {
    font-size: 14px;
    padding: 40px 0px 0px 40px;
  }
  .projectsTextBox.left {
    padding: 40px 40px 0px 0px;
  }
  .projectsSubtitle {
    padding-top: 40px;
  }
}
@media (min-width: 1024px) {
  .projectsFindOutMore {
    font-size: 16px;
  }

  .projectsText {
    font-size: 16px;
  }

  .projectsSubtitle {
    font-size: 16px;
  }

  .moreProjects {
    font-size: 16px;
  }
}

.skillsContainer {
  margin: 0px -5px;
}

.skillsButtonsContainer {
  margin-top: 10px;
}

.skillButton {
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  color: white;
  background-color: transparent;
  padding: 2px 10px 2px 8px;
  border-radius: 20px;
  margin: 5px;
  cursor: pointer;
}

.skillsSubtitle {
  margin-top: 40px;
  margin-left: 5px;
  font-size: 14px;
  color: white;
}

.engineering {
  border: 2px solid #009ee1;
}

.engineering.active {
  background-color: #009ee1;
}

.development {
  border: 2px solid #e3007a;
}

.development.active {
  background-color: #e3007a;
}

.design {
  border: 2px solid #ffed00;
}

.design.active {
  background-color: #ffed00;
}

@media (min-width: 768px) {
  .skillButton {
    font-size: 24px;
  }
  .skillsSubtitle {
    font-size: 16px;
  }
}

@media (hover: hover) {
  .engineering:hover,
  .engineering.active {
    background-color: #009ee1;
  }
  .development:hover,
  .development.active {
    background-color: #e3007a;
  }
  .design:hover,
  .design.active {
    background-color: #ffed00;
  }
}

.wheatearContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  padding-bottom: 20px;
  margin: auto;
}

.wheatearVisual {
  max-width: 100%;
  height: auto;
  width: 100%;
  max-width: 420px;
  padding: 20px 0;
}

.link {
  text-decoration: underline;
}

@media (min-width: 1024px) {
  .wheatearContentContainer {
    font-size: 16px;
    width: calc(100vw - 390px);
  }
}

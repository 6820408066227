.solarPotentialContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  width: 100%;
  padding-bottom: 20px;
  text-align: left;
  @media (min-width: 1440px) {
    padding: 0 calc(50% - 512px);
  }
}

.inlineLink {
  width: 100%;
}

.solarPotentialContentContainer a {
  text-decoration: underline;
  color: orange;
}

.solarPotentialVisual {
  max-width: 100%;
  height: auto;
  width: 100%;
  max-width: 420px;
  padding: 20px 0;
}

.solarPotentialVisualPhone {
  max-width: 100%;
  height: auto;
  width: 100%;
  max-width: 240px;
  padding: 20px 0;
}

.solarPotentialFlowchart {
  max-width: 100%;
  height: auto;
  width: 70%;
  padding: 20px 0;
}

@media (min-width: 1024px) {
  .solarPotentialContentContainer {
    font-size: 16px;
  }
}

.solarPotentialSubtitle {
  align-self: flex-start;
  font-weight: bold;
}

.imagePair {
  display: flex;
  gap: 8px;
  width: 70%;
  justify-content: center;
  align-items: center;
}

.imagePairImage {
  width: 40%;
}

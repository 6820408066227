.content {
  color: white;
}

.title {
  font-size: 20px;
  margin-bottom: 10px;
}

.subtitle {
  color: #9b9b9b;
  font-size: 14px;
}

.bulletPoints ul {
  margin-bottom: 10px;
  padding-inline-start: 24px;
  font-size: 14px;
}

.bulletPoints li {
  margin-bottom: 10px;
}

@media (min-width: 1024px) {
  .title {
    font-size: 24px;
  }
  .subtitle {
    font-size: 16px;
  }
  .bulletPoints li {
    font-size: 16px;
  }
}
